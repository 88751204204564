<template>
	<div class="settings-page py-5" :class="$mq == 'sm' ? 'px-4' : 'container container-2'">
		<div class="mb-3 dF aC" style="font-size: 24px; gap: 20px; justify-content: space-between">
			<div>
				<a-icon type="arrow-left" @click="$router.go(-1)" />
				<strong class="ml-3">{{
		user.firstName + " " + user.lastName
	}}</strong>
			</div>
		</div>
		<bh-loading :show="loading" />
		<a-form class="profile mt-3" @submit="submit">
			<div :class="$mq == 'md' ? '' : 'dF'" style="gap: 3em">
				<div :style="$mq == 'md' ? 'width:100%' : 'width:25%'">
					<a-card class="profile-card">
						<img v-if="userData.avatar !== '' && userData.avatar" :src="userData.avatar" class="mb-3"
							alt="avatar" style="
                                background-size: cover;
                                border-radius: 50% 50% 50% 50%;
                                width: 100px;
                                height: 100px;
                                object-fit: cover;
                            " />
						<a-avatar v-else class="mb-3" :size="100" style="font-size: 50px">
							{{ getInitial(user.firstName || '', user.lastName || '') }}
						</a-avatar>
						<h5>
							{{ (user.firstName || '') + " " + (user.lastName || '') }}
						</h5>
						<div style="color: #9ea0a5">
							{{ user.email }}
						</div>
						<template slot="actions">
							<a-upload name="avatar" v-model="img" :multiple="true" :showUploadList="false"
								:beforeUpload="beforeUpload" @change="handleChange">
								<div class="footer-button">UPLOAD IMAGE</div>
							</a-upload>
							<div v-if="userData.avatar && userData.avatar !== ''" @click="changeAvatar"
								class="footer-button">
								REMOVE IMAGE
							</div>
						</template>
					</a-card>
				</div>
				<div :class="$mq == 'md' ? 'mt-3' : ''" :style="$mq == 'md' ? 'width:100%' : 'width:75%'">
					<a-card>
						<h6 class="mb-4 text-dark">
							<strong>Basic Profile</strong>
						</h6>
						<hr style="margin-left: -24px; margin-right: -24px" />

						<a-form-model ref="ruleForm" :model="userData" :rules="rules">
							<a-row :gutter="16">
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item ref="firstName" label="First Name" prop="firstName"
										:rules="req('Please enter your First Name')">
										<a-input v-model="userData.firstName" placeholder="First Name" size="large">
										</a-input>
									</a-form-model-item>
								</a-col>
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item ref="lastName" label="Last Name" prop="lastName"
										:rules="req('Please enter your Last Name')">
										<a-input v-model="userData.lastName" placeholder="Last Name" size="large">
										</a-input>
									</a-form-model-item>
								</a-col>
							</a-row>
							<a-row :gutter="16">
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item prop="userRole" label="Your Role"
										:rules="req('Please enter your Role')">
										<a-input class="input-border-style" size="large" v-model="userData.userRole" />
									</a-form-model-item>
								</a-col>
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item prop="phone" label="Phone Number"
										:rules="req('Please enter your Business Phone Number')">
										<a-input v-model="userData.phone" placeholder="Phone Number" size="large"
											type="number">
										</a-input>
									</a-form-model-item>
								</a-col>
							</a-row>
							<a-row :gutter="16">
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item prop="company" label="Company Name"
										:rules="req('Please enter your Company Name')">
										<a-input v-model="userData.company" placeholder="Company" size="large">
										</a-input>
									</a-form-model-item>
								</a-col>
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item prop="industry" label="Type Of Business"
										:rules="req('Please select your Type Of Business')">
										<a-select :default-value="userData.industry" @change="selectIndustry"
											size="large">
											<a-select-option value="Builder">
												Builder
											</a-select-option>
											<a-select-option value="Real Estate Broker">
												Real Estate Broker
											</a-select-option>
											<a-select-option value="Advertising Agency">
												Advertising Agency
											</a-select-option>
											<a-select-option value="Freelancer">
												Freelancer
											</a-select-option>
											<a-select-option value="Other">
												Other
											</a-select-option>
										</a-select>
									</a-form-model-item>
								</a-col>
							</a-row>
							<a-row :gutter="16">
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item prop="address" label="Address"
										:rules="req('Please enter your Address')">
										<a-input class="input-border-style" size="large" v-model="userData.address" />
									</a-form-model-item>
								</a-col>
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item prop="country" label="Country"
										:rules="req('Please select your Country')">
										<a-select show-search size="large" class="dropdown-style"
											v-model="userData.country"
											@change="userData.region = null; userData.postal = null;">
											<a-select-option v-for="country in countries" :key="country.value"
												:value="country.name">
												{{ country.name }}
											</a-select-option>
										</a-select>
									</a-form-model-item>
								</a-col>
							</a-row>
							<a-row :gutter="16">
								<a-col :span="$mq == 'sm' ? 24 : 8">
									<a-form-model-item prop="city" label="City" :rules="req('Please enter your City')">
										<a-input class="input-border-style" size="large" v-model="userData.city" />
									</a-form-model-item>
								</a-col>
								<a-col :span="$mq == 'sm' ? 24 : 8">
									<a-form-model-item prop="region" label="State/Province"
										:rules="req('Please enter your State/Province')">
										<a-input class="input-border-style" size="large" v-model="userData.region" />
									</a-form-model-item>
								</a-col>
								<a-col :span="$mq == 'sm' ? 24 : 8">
									<a-form-model-item prop="postal" label="Zip/Postal Code"
										:rules="req('Please enter your Zip/Postal Code')">
										<a-input class="input-border-style" size="large" v-model="userData.postal" />
									</a-form-model-item>
								</a-col>
							</a-row>
							<a-row :gutter="16">
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item ref="email" label="Email Address" prop="email" required>
										<a-input v-model="userData.email" placeholder="Email Address" size="large"
											disabled>
										</a-input>
									</a-form-model-item>
								</a-col>
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-form-model-item ref="password" label="Password" prop="password">
										<a-input-password v-model="userData.password"
											placeholder="Enter Your New Password" size="large" required>
										</a-input-password>
									</a-form-model-item>
								</a-col>
							</a-row>
							<a-alert
								message="Password should have minimum fourteen characters, at least one uppercase letter, one lowercase letter, one number and one special character from @$!%*?&."
								type="warning" show-icon />

						</a-form-model>

						<div class="dF jE w-full mt-3">
							<a-button type="primary" @click="submit">SAVE</a-button>
						</div>
					</a-card>
				</div>
			</div>
		</a-form>
	</div>
</template>

<script>
import { getBase64 } from "bh-mod";
import { getInitial } from "bh-mod";
import postalCodes from "postal-codes-js";
import countryData from "@/staticData/countryData";
import bhLoading from "bh-mod/components/common/Loading";

export default {
	components: {
		bhLoading,
	},
	data: function () {
		return {
			fileList: [],
			img: null,
			loading: false,
			form: this.$form.createForm(this),
			formData: null,
			userData: {
				avatar: "",
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				company: "",
				industry: "",
				userRole: "",
				address: "",
				country: "",
				city: "",
				region: "",
				postal: "",
				settings: {},
			},
			timeZones: [
				{ value: 0, label: "(GMT) - London" },
				{ value: 1, label: "(GMT+1:00) - Paris" },
				{ value: 2, label: "(GMT+2:00) - Cairo" },
				{ value: 3, label: "(GMT+3:00) - Moscow" },
				{ value: 4, label: "(GMT+4:00) - Dubai" },
				{ value: 5, label: "(GMT+5:00) - Karachi" },
				{ value: 6, label: "(GMT+6:00) - Dhaka" },
				{ value: 7, label: "(GMT+7:00) - Bangkok" },
				{ value: 8, label: "(GMT+8:00) - Shanghai" },
				{ value: 9, label: "(GMT+9:00) - Tokyo" },
				{ value: 10, label: "(GMT+10:00) - Sydney" },
				{ value: 11, label: "(GMT+11:00) - Noumea" },
				{ value: 12, label: "(GMT+12:00) - Auckland" },
				{ value: -1, label: "(GMT-1:00) - Greenland" },
				{ value: -2, label: "(GMT-2:00) - Brazil" },
				{ value: -3, label: "(GMT-3:00) - Sao Paulo" },
				{ value: -4, label: "(GMT-4:00) - Halifax" },
				{ value: -5, label: "(GMT-5:00) - Toronto" },
				{ value: -6, label: "(GMT-6:00) - Mexico City" },
				{ value: -7, label: "(GMT-7:00)  - Edmonton" },
				{ value: -8, label: "(GMT-8:00) - Vancouver" },
				{ value: -9, label: "(GMT-9:00) - Anchorage" },
				{ value: -10, label: "(GMT-10:00) - Honolulu" },
				{ value: -11, label: "(GMT-11:00) - New Zealand" },
			],
			dateFormat: ["MM/DD/YYYY", "DD/MM/YYYY", "YYYY/MM/DD"],
			regionalSettings: {
				timeZone: 0,
				dateFormat: "",
				timeFormat: 0,
			},
			notification: {
				email: false,
				push: false,
			},
			countries: countryData.countryList,
		};
	},
	computed: {
		user() {
			return this.$store.state.user.user;
		},
		instances() {
			return this.$store.state.instances;
		},
		editDrawer() {
			return this.$store.state.editDrawer.show;
		},
		rules() {
			return {
				email: [{ validator: this.validateEmail, trigger: "change" }],
				postal: [
					{ validator: this.validatePostalCodes, trigger: "change" },
				],
				firstName: [
					{
						required: true,
						message: "Please enter your first name",
						trigger: "change",
					},
				],
				lastName: [
					{
						required: true,
						message: "Please enter your last name",
						trigger: "change",
					},
				],
				userRole: [
					{
						required: true,
						message: "Please enter your role",
						trigger: "change",
					},
				],
				phone: [
					{
						required: true,
						message: "Please enter your business number",
						trigger: "change",
					},
				],
				company: [
					{
						required: true,
						message: "Please enter your company name",
						trigger: "change",
					},
				],
				industry: [
					{
						required: true,
						message: "Please select your business type",
						trigger: "change",
					},
				],
				address: [
					{
						required: true,
						message: "Please enter your address",
						trigger: "change",
					},
				],
				country: [
					{
						required: true,
						message: "Please select your country",
						trigger: "change",
					},
				],
				region: [
					{
						required: true,
						message: "Please enter your region",
						trigger: "change",
					},
				],
				city: [
					{
						required: true,
						message: "Please enter your city",
						trigger: "change",
					},
				],
			};
		},
	},
	methods: {
		req: (msg) => ({ required: true, message: msg }),

		validateEmail(rule, value, callback) {
			if (value === "") {
				callback(new Error("Please enter an email"));
			} else {
				const result =
					/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(
						value
					);
				if (result) {
					callback();
				} else {
					callback(new Error("That's not a valid email address."));
				}
			}
		},

		validatePostalCodes(rule, value, callback) {
			if (value === "") {
				callback(new Error("Please enter a Zip/Postal Code"));
			} else {
				if (!this.userData.country) {
					callback(new Error("Please select a country first"));
				}
				const countryCode =
					countryData.countries[this.userData.country];
				const result = postalCodes.validate(countryCode, value);
				if (result === true) {
					callback();
				} else {
					callback(new Error(result));
				}
			}
		},

		selectIndustry(value) {
			this.userData.industry = value;
		},
		getInitial,
		changeAvatar() {
			this.userData.avatar = "";
		},
		async submit(e) {
			e.preventDefault();
			let formData = this.formData;
			let avatarUrl = false;
			let avatarId = false;
			if (formData) {
				let okay = false;
				for (var [key, value] of formData.entries()) {
					if (value) okay = true;
				}
				if (okay) {
					let url = `/upload`;
					let { data } = await this.$api.post(url, formData, {
						headers: { "Content-Type": "multipart/form-data" },
					});
					if (Array.isArray(data) && data.length) {
						data = data[0];
						if (data.url) {
							avatarUrl = data.url;
							avatarId = data.id;
						}
					}
				}
			}
			let sendObj = JSON.parse(JSON.stringify(this.userData));

			if (avatarUrl) sendObj.avatar = avatarUrl;
			if (avatarId) sendObj.avatarId = avatarId;

			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					let regional = {};
					let notification = {};
					let objectSettings = JSON.parse(
						JSON.stringify(this.user.settings)
					);
					if (
						this.user &&
						this.user.settings &&
						this.user.settings.options &&
						this.user.settings.options.notification
					) {
						notification =
							this.user.settings.options.notification;
					}
					regional = this.regionalSettings;
					let successSettings = false;
					let userSettings = {};
					objectSettings.notification = notification;
					objectSettings.regional = regional;
					this.$api
						.post("/settings", { options: objectSettings })
						.then(({ data }) => {
							successSettings = true;
							userSettings = data;
						});
					let url = `/users/${this.user.id}`;
					this.$api
						.put(url, sendObj)
						.then(({ data }) => {
							if (data.id) {
								if (successSettings) {
									data.settings = userSettings;
								}
								this.formData = null;
								this.$store.commit("UPDATE_USER", data);
								let {
									firstName,
									lastName,
									email,
									avatar,
									phone,
									company,
									industry,
									userRole,
									address,
									country,
									city,
									region,
									postal,
									settings,
								} = this.user;
								this.userData = {
									firstName,
									lastName,
									email,
									avatar,
									phone,
									company,
									industry,
									userRole,
									address,
									country,
									city,
									region,
									postal,
									settings,
								};
								this.$notification["success"]({
									message: "Updated",
									description:
										"Your profile updated successfully!"
								});
							}
						})
						.catch((err) => {
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, "Error while updating user settings. Please try again!"));
							}
						});
				} else {
					console.log("Invalid field details");
					return false;
				}
			});
		},
		handleChange(info) {
			this.formData = new FormData();

			this.formData.append("files", info.file, info.file.name);

			// Get this url from response in real world.
			getBase64(info.file, (imageUrl) => {
				this.userData.avatar = imageUrl;
				this.loading = false;
			});
		},
		beforeUpload(file) {
			const isJPG = file.type === "image/jpeg";
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				this.$message.error("Image must smaller than 2MB!");
				return false;
			}
			return false;
		},
	},
	created() {
		let {
			firstName,
			lastName,
			email,
			avatar,
			phone,
			company,
			industry,
			userRole,
			address,
			country,
			city,
			region,
			postal,
			settings,
		} = this.user;
		this.userData = {
			firstName,
			lastName,
			email,
			avatar,
			phone,
			company,
			industry,
			userRole,
			address,
			country,
			city,
			region,
			postal,
			settings,
		};
		if (
			this.user.settings == null ||
			!this.user.settings ||
			!this.user.settings.options ||
			!this.user.settings.options.regional ||
			Object.keys(this.user.settings.options.regional).length == 0
		) {
			this.regionalSettings.timeZone = -5;
			this.regionalSettings.dateFormat = "DD/MM/YYYY";
			this.regionalSettings.timeFormat = 12;
		} else if (
			this.user.settings &&
			this.user.settings.options &&
			this.user.settings.options.regional &&
			Object.keys(this.user.settings.options.regional).length != 0
		) {
			let settings = this.user.settings;
			this.regionalSettings.timeZone = settings.options.regional.timeZone;
			this.regionalSettings.dateFormat =
				settings.options.regional.dateFormat;
			this.regionalSettings.timeFormat =
				settings.options.regional.timeFormat;
		}
		if (
			this.user.settings == null ||
			!this.user.settings ||
			!this.user.settings.options ||
			!this.user.settings.options.notification ||
			Object.keys(this.user.settings.options.notification).length ==
			0
		) {
			this.notification.email = true;
			this.notification.push = false;
		} else if (
			this.user.settings &&
			this.user.settings.options &&
			this.user.settings.options.notification &&
			Object.keys(this.user.settings.options.notification).length !=
			0
		) {
			this.notification.email =
				this.user.settings.options.notification.email;
			this.notification.push =
				this.user.settings.options.notification.push;
		}
	},
};
</script>

<style lang="scss">
.container-2 {
	max-width: 100vw;
	margin-left: 0;
	margin-right: 0;
	padding-right: 25px;
	padding-left: 25px;
}

.profile-card {
	text-align: center;
}

.ant-card-actions>li:not(:last-child) {
	border-right: none;
}

.footer-button {
	color: var(--black);
	transition: color 0.2s ease-in;
}

.footer-button:hover {
	color: var(--orange);
	transition: color 0.2s ease-in;
}

.ant-form-item-label>label::after {
	content: "";
}

.settings-page {
	background-color: #f7f5f9;
}
</style>
